import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'dayjs/locale/es';
import 'dayjs/locale/ko';

// Using this polyfill until there is a better browser support for URLPattern
// https://developer.mozilla.org/en-US/docs/Web/API/URL_Pattern_API#browser_compatibility
import { URLPattern } from 'urlpattern-polyfill';
import { sanitize } from './domPurify';

dayjs.extend(relativeTime);

export const toLowerWords = text =>
  (text || '')
    .toLowerCase()
    .replace(/[^\s0-9a-z]/gi, '')
    .split(/\s+/g)
    .filter(x => x.length > 0);

export const kFormatter = (num, decimals = 1) => {
  if (!num) return 0;

  return Math.abs(num) > 999999
    ? `${Math.sign(num) * (Math.abs(num) / 1000000).toFixed(decimals)}M`
    : Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(decimals)}K`
    : `${Math.sign(num) * Math.abs(num)}`;
};

export const toUpperWords = text =>
  (text || '')
    .toUpperCase()
    .replace(/[^\s0-9a-z]/gi, '')
    .split(/\s+/g)
    .filter(x => x.length > 0);

export const tokenMatch = (h, n) => {
  const needles = toLowerWords(n);
  const haystack = toLowerWords(h);

  outer: for (const needle of needles) {
    for (let i = 0, l = haystack.length; i < l; i += 1) {
      if (haystack[i].indexOf(needle) >= 0) {
        haystack.splice(i, 1);
        continue outer;
      }
    }
    return false;
  }
  return true;
};

export const localizedDateTime = (dateString, format) => {
  const locale = window.localStorage.getItem('locale');
  return dayjs(dateString)
    .locale(locale ?? 'en')
    .format(format ?? 'MM/DD/YYYY hh:mm A');
};

export const dateToAge = dateString => {
  if (!dateString) return '';

  return dayjs(dateString).fromNow();
};

export const truncate = (s, l, ellipsis = '...') => {
  if (!s) {
    return s;
  }
  if (s.length > l) {
    return `${s.substring(0, l).trim()}${ellipsis}`;
  }
  return s;
};

export const noBreaks = t => {
  return t.replace(/(\r\n|\n|\r)/gm, ' ');
};

export const roundTo = (number, places = 0) => {
  const multiplier = 10 ** places;
  return Math.round(number * multiplier) / multiplier;
};

export const insertInto = (s, insert, p) => {
  if (s.length < p) return s;
  return `${s.slice(0, p)}${insert}${s.slice(p)}`;
};

// normalize, format, validate
export const normalizePhone = phone => {
  return phone.match(/\+\d+/) ? phone : `+1${phone}`;
};

export const fmtScore = score => {
  if (score < 1000) return score;

  const formatted = score / 1000;
  if (formatted < 100) return `${roundTo(formatted, 1)}K`;
  return `${roundTo(formatted, 0)}K`;
};

export const fmtNumber = n => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const fmtDollars = d => {
  if (Number.isInteger(d)) return d;
  return d.toFixed(2);
};

export const allNumeric = s => {
  // are chars are numeric?
  if (!s) return false;
  return !s.match(/\D/);
};

export const isValidEmail = email => {
  if (!email) {
    return false;
  }
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPhone = phone => {
  return phone.match(/^\d{10}$/);
};

// Turn url into a puny decoded url to prevent homograph attacks
export const punyEncodedURL = url => {
  try {
    const { protocol, hostname, pathname } = new URLPattern(url);
    return `${protocol}://${hostname}${pathname}`;
  } catch (e) {
    // parsing failed - show regular url even though it's probably empty or invalid
    return url;
  }
};

export const parseUrl = url => {
  if (!url) return null;

  // Some older clients have a `website_url` that starts with "www"
  // In this case we need to add the protocol because otherwise the link goes to "{impactive_domain}/${url}"
  // We already have validation for this on the admin side, but we need to take care of the old client
  if (isURLStartingWithWWW(url)) {
    url = `https://${url}`;
  }

  if (isCorrectUrl(url)) {
    return url;
  }

  return null;
};

const isURLStartingWithWWW = url => {
  const pattern = /^www\.[a-zA-Z0-9-]/;
  return pattern.test(url);
};

const isCorrectUrl = url => {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  return urlRegex.test(url);
};

// Convert links to clickable in text
export const convertLinks = (description, { skipRegex } = {}) => {
  if (!description) return '';

  const regular =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

  return sanitize(
    description.replaceAll(regular, link => {
      if (skipRegex?.test(link)) {
        return link;
      }
      return `<a href="${link}" target="_blank" rel="noopener noreferrer">${link}</a>`;
    }),
  );
};

export const replaceDefaultCampaignImage = url =>
  [
    'https://assets.impactive.io/default_profile_img.png',
    'https://assets.outvote.io/default_profile_img.png',
  ].includes(url)
    ? undefined
    : url;

export const onlyNumbers = s => s?.replace(/\D/g, '');

export const capitalize = str => {
  if (!str) return '';

  const cap = s => `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`;

  return str.split(' ').map(cap).join(' ');
};

export const personalizedLinkRegex = new RegExp(
  'https://links(-staging|-dev)?\\.impactive\\.io/[a-zA-Z0-9_\\-/=?&.%]*',
);

export const checkForPersonalizedLinks = script => {
  return personalizedLinkRegex.test(script);
};
